.button {
    font-size: 24px;
    padding: 5px 40px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: rgb(177, 167, 167);
    cursor: pointer;
    opacity: .8;
}

.button:hover {
    opacity: 1;
}