.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
}

@media (max-width: 520px) {
    .container {
        flex-direction: column;
    }
}