.color1 {
    background-color: #FD8A8A;
}

.color2 {
    background-color: #F1F7B5;
}

.color3 {
    background-color: #A8D1D1;
}

.color4 {
    background-color: #9EA1D4;
}

.color5 {
    background-color: #243763;
    color: white;
}

.color6 {
    background-color: #FF6E31;
}

.color7 {
    background-color: #FFEBB7;
}

.color8 {
    background-color: #AD8E70;
}

.color9 {
    background-color: #C0EEE4;
}

.color10 {
    background-color: #F8F988;
}

.color11 {
    background-color: #FFCAC8;
}

.color12 {
    background-color: #FF9E9E;
}

.color13 {
    background-color: #FFFBEB;
}

.color14 {
    background-color: #495579;
    color: white;
}

.color15 {
    background-color: #263159;
    color: white;
}

.color16 {
    background-color: #251749;
    color: white;
}

.color17 {
    background-color: #00FFF6;
}

.color18 {
    background-color: #00E7FF;
}

.color19 {
    background-color: #009EFF;
}

.color20 {
    background-color: #3c4dff;
    color: white
}

.colorW {
    background-color: #fff;
}

.active {
    background-color: #50adce;
}