.shop_item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
}

.shop_item span {
    padding: 5px;
}

.shop_item button {
    font-size: 20px;
    margin: 5px;
}