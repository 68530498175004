.shop {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 40px;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 520px) {
    .shop {
        margin-left: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}