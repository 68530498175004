.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressBar {
    position: relative;
    width: 400px;
    height: 50px;
    border: 1px solid black;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #ccc;
    overflow: hidden;
}

.progressLine {
    background-color: rgb(255, 15, 240);
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    transition: cubic-bezier(.0,.0,.0,.0) 0.1s;
}

@media (max-width: 520px) {
    .container {
        margin-top: 30px;
        min-width: 240px;
    }

    .progressBar {
        width: 70vw;
    }
}