.footer {
    color: rgb(128, 128, 128);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(206, 240, 246);
    border-top: 1px solid black;
}

.llc {
    padding-left: calc(100vw/2 - 70px);
}

.logo {
    left: 800px;
    bottom: 0;
    padding: 2px;
}

@media (max-width: 520px) {
    .logo {
        visibility: hidden;
    }
}