.container {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 420px) {
    .container {
        min-width: 330px;
    }
}