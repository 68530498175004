.cell {
    width: 60px;
    height: 60px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color .2s;
}

.over {
    background: lightgray;
}

.grabble {
    cursor: grab;   
}